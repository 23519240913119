'use strict';

$(function() {
  $('.slick-mv').slick({
      autoplay: true,
      autoplaySpeed:4000,
      speed :1000,
      dots: true,
      fade:true,
      arrows:false,
      responsive: [
          {
            breakpoint: 1024, // 399px以下のサイズに適用
            settings: {
            },
          },
          {
              breakpoint: 768, // 399px以下のサイズに適用
              settings: {
              },
          },
      ],
  });
});

$(function() {
    $('.slick-golf').slick({
        autoplay: true,
        dots: false,
        centerMode: true,
        slidesToShow: 5,
        centerPadding: '0',
        responsive: [
            {
              breakpoint: 1024, // 399px以下のサイズに適用
              settings: {
                slidesToShow: 3,
              },
            },
            {
                breakpoint: 768, // 399px以下のサイズに適用
                settings: {
                  slidesToShow: 3,
                },
            },
        ],
    });
});


$(function() {
  $('.slick-selection').slick({
      autoplay: true,
      dots: false,
      slidesToShow: 4,
      responsive: [
          {
              breakpoint: 1024, // 399px以下のサイズに適用
              settings: {
              },
          },
          {
              breakpoint: 768, // 399px以下のサイズに適用
              settings: {
                centerMode: true,
                slidesToShow: 1,
                centerPadding: '20px',
              },
          },
      ],
  });
});